import { withDependencies, optional } from '@wix/thunderbolt-ioc'
import { Animations, IAnimations } from 'feature-animations'
import type { IResolvableReadyForScrollPromise, IWindowScrollAPI } from './types'
import { calcScrollDuration } from './scrollUtils'
import { BrowserWindowSymbol, ViewMode, ViewModeSym } from '@wix/thunderbolt-symbols'
import { PopupUtilsSymbol, IPopupUtils } from 'feature-popups'
import { ResolvableReadyForScrollPromiseSymbol } from './symbols'

const isElementOrAncestorFixed = (element: HTMLElement) => {
	let elem = element
	while (elem && elem !== window.document.body) {
		if (window.getComputedStyle(elem).getPropertyValue('position').toLowerCase() === 'fixed') {
			return true
		}
		elem = elem.offsetParent as HTMLElement
	}
	return false
}

const getCompClientYForScroll = (window: Window, compNode: HTMLElement) => {
	const wixAdsElement = window.document.getElementById('WIX_ADS')
	const wixAdsHeight = wixAdsElement ? wixAdsElement.offsetHeight : 0
	const siteHeaderPlaceholderElement = window.document.getElementById('SITE_HEADER-placeholder')
	const siteHeaderPlaceholderHeight = siteHeaderPlaceholderElement ? siteHeaderPlaceholderElement.offsetHeight : 0
	const bodyTop = window.document.body.getBoundingClientRect().top
	const compTop = compNode.getBoundingClientRect().top
	return compTop - bodyTop - wixAdsHeight - siteHeaderPlaceholderHeight
}

const getScrollableElement = (popupUtils?: IPopupUtils) =>
	popupUtils?.getCurrentPopupId() ? window.document.getElementById('POPUPS_ROOT')! : window

export const WindowScroll = withDependencies(
	[
		BrowserWindowSymbol,
		ViewModeSym,
		ResolvableReadyForScrollPromiseSymbol,
		optional(PopupUtilsSymbol),
		optional(Animations),
	],
	(
		window: Window,
		viewMode: ViewMode,
		{ readyForScrollPromise }: IResolvableReadyForScrollPromise,
		popupUtils?: IPopupUtils,
		animations?: IAnimations
	): IWindowScrollAPI => {
		const animatedScrollTo = async (targetY: number): Promise<void> => {
			if (!animations) {
				return
			}
			const animationInstance = await animations.getInstance()
			await readyForScrollPromise
			const isMobile = viewMode === 'mobile'
			const easingName = isMobile ? 'Quint.easeOut' : 'Sine.easeInOut'
			const duration = calcScrollDuration(window.pageYOffset, targetY, isMobile)
			const scrollableElement = getScrollableElement(popupUtils)

			return new Promise((resolve) => {
				animationInstance.runAnimationOnElements('BaseScroll', [scrollableElement], duration, 0, {
					y: targetY,
					ease: easingName,
					callbacks: {
						onComplete: () => resolve(),
					},
				})
			})
		}

		const scrollToComponent = async (targetCompId: string) => {
			await readyForScrollPromise
			const targetElement = window.document.getElementById(targetCompId)!
			if (isElementOrAncestorFixed(targetElement) && !popupUtils?.getCurrentPopupId()) {
				return
			}
			const compClientYForScroll = getCompClientYForScroll(window, targetElement)
			await animatedScrollTo(compClientYForScroll)
			targetElement.focus()
			// target component can be non focusable - adding tabIndex make it focusable
			if (document.activeElement!.id !== targetCompId) {
				targetElement.setAttribute('tabindex', '-1')
				targetElement.focus()
			}
		}

		return {
			animatedScrollTo,
			scrollToComponent,
		}
	}
)
